import {call, put, delay, take, fork, cancel} from "redux-saga/effects";
import {onError, safe} from "./handlerError";
import {GetResource, PutResource} from "../../utils/HttpUtil";
import {
    SET_BET_LIST,
    REQUEST_BET_LIST,
    REQUEST_BET_LIST_FILTER,
    SET_BET_LIST_FILTER,
    REQUEST_BET_CSV_DOWNLOADER_URL,
    SET_BET_CSV_DOWNLOADER_URL,
    REQUEST_CANCEL_BET_CSV_DOWNLOAD,
    SET_CANCEL_BET_CSV_DOWNLOAD,
    SET_ALLOW_CSV_CANCEL
} from "@actions/actionTypes";
import {getQuery} from "../../utils/CommonUtils";
import {getFormattedBody} from "@services/TableServices";
import {waitForFile} from "@services/CsvDownloadService";
import "./csvProcess";

let downloadTask

const takeLatest = (patternOrChannel, saga, ...args) => fork(function*() {
    while (true) {
        const action = yield take(patternOrChannel)
        if (patternOrChannel === REQUEST_BET_CSV_DOWNLOADER_URL) {
            downloadTask = yield fork(saga, ...args.concat(action))
        } else {
            yield fork(saga, ...args.concat(action))
        }

    }
})

function* fetchBetList({params}) {
    let query = getQuery(params);
    const bets = yield call(GetResource, `/bets/stats?${query}`);
    yield put({type: SET_BET_LIST, bets})
}

function* fetchBetListFilter({params}) {
    const filter = yield call(GetResource, `/bets/statsfilter`);
    yield put({type: SET_BET_LIST_FILTER, filter})
}

function* fetchBetCsvDownloaderUrl({data, ...restProps}) {
    const checkProcessUrl = "/bets/getreporturl";
    let id = restProps.processId;
    if (!id) {
        const formattedData = getFormattedBody(data)
        const query = getQuery(formattedData)
        id = yield call(GetResource, `/bets/generatereport?${query}`);
    }
    window.CsvProcess.setProcess({
        actionType: REQUEST_BET_CSV_DOWNLOADER_URL,
        processId: id,
        checkProcessUrl
    })
    yield put({type: SET_ALLOW_CSV_CANCEL, allow: {allowBets: true}})
    const link = yield waitForFile(REQUEST_BET_CSV_DOWNLOADER_URL, id, checkProcessUrl);
    yield put({type: SET_BET_CSV_DOWNLOADER_URL, link})
    yield put({type: SET_CANCEL_BET_CSV_DOWNLOAD})
    yield put({type: SET_ALLOW_CSV_CANCEL, allow: {allowBets: false}})
    window.CsvProcess.removeProcess(id);
}

function* cancelBetsCsvDownload(processId) {
    window.CsvProcess.setCanceled(processId);
    if (processId) {
        yield call(PutResource, `/bets/cancelreport?key=${processId}`);
    }
    window.CsvProcess.removeProcess(processId);
    yield delay(2000)
    yield put({type: SET_ALLOW_CSV_CANCEL, allow: {allowBets: false}})
    yield put({type: SET_BET_CSV_DOWNLOADER_URL, link: ""});
    yield put({type: SET_CANCEL_BET_CSV_DOWNLOAD})
}

function* cancelFetching() {
    const processId = window.CsvProcess.getProcessIdByAction(REQUEST_BET_CSV_DOWNLOADER_URL)
    yield cancel(downloadTask);
    yield cancelBetsCsvDownload(processId)
}

function* betsSaga() {
    yield takeLatest(REQUEST_BET_LIST, safe(onError, fetchBetList))
    yield takeLatest(REQUEST_BET_LIST_FILTER, safe(onError, fetchBetListFilter))
    yield takeLatest(REQUEST_BET_CSV_DOWNLOADER_URL, safe(onError, fetchBetCsvDownloaderUrl))
    yield takeLatest(REQUEST_CANCEL_BET_CSV_DOWNLOAD, safe(onError, cancelFetching))
}

export default betsSaga