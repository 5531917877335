export const DefaultLanguage = 'EN';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];

export const TransactionStatus = {
    0: "pending",
    1: "success",
    2: "failed",
    3: "timeout",
    4: "trying"
}

export const TransactionType = {
    0: "bet",
    1: "win",
    2: "lose",
    3: "refund"
}

export const BetStatus = {
    0: "active",
    1: "win",
    2: "lose",
    3: "refund",
    "active": 0,
    "win": 1,
    "lose": 2,
    "refund": 3
}

export const GameType = {
    1: "public",
    2: "private",
}

export const CsvProgresstype = {
    inprogress: 1,
    finished: 2,
    error: 3,
    cancelled: 4,
    emptyTable: 5
}

export const GameTemplateCurrency = [
    "USD",
    "GBP",
    "INR",
    "HKD",
    "AED",
];

export const PlayersCount = [2, 3, 4, 5, 6]