import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getUrlValue, getUrlString} from "../../../utils/CommonUtils";
import TableLoader from "@components/Loaders/tableLoader";
import {Table} from "antd";
import {TransactionType, TransactionStatus} from "@constants/Enums";
import moment from "moment-timezone";
import TransactionsFilter from "@components/TransactionsFilter/TransactionsFilter";
import classNames from "classnames";
import {setDoneMsg, setErrorMsg} from "@actions/alertAction";
import {getTransactionsList} from "@actions/transactionsActions"
const keysOfDate = ["createdFrom","createdTo"]
import {copyLabel} from "@services/TableServices";




function Transactions({intl, loading, transactionsList, getTransactionsList, setDoneMsg, setErrorMsg}) {

    let history = useHistory()
    const search = history && history.location && history.location.search

    const [totalPages, setTotalPages] = useState(0);
    const [limit, setLimit] = useState(+getUrlValue(search).limit || 10);
    const [page, setPage] = useState(+getUrlValue(search).page || 1);
    const [showFilter, setShowFilter] = useState(false);

    const columns = [
        {
            title: intl.formatMessage({id: "id"}),
            dataIndex: 'id',
            width: "fit-content",
            render: (text, record) => {
                return <span className="copyable" onDoubleClick={(e) => {
                    copyLabel(e, record.id)
                        .then(() => {
                            setDoneMsg("text_is_copied")
                        }).catch(() => {
                        setErrorMsg("something_wrong_the_text_is_not_copied")
                    })
                }}>{record.id}</span>
            }
        },
        {
            title: intl.formatMessage({id: "session"}),
            dataIndex: 'sessionId',
            width: "fit-content",
            render: (text, record) => {
                return <span className="copyable" onDoubleClick={(e) => {
                    copyLabel(e, record.sessionId)
                        .then(() => {
                            setDoneMsg("text_is_copied")
                        }).catch(() => {
                        setErrorMsg("something_wrong_the_text_is_not_copied")
                    })
                }}>{record.sessionId}</span>
            }
        },
        {
            title: intl.formatMessage({id: "player_id"}),
            dataIndex: 'playerId',
            width: "fit-content",
        },
        {
            title: intl.formatMessage({id: "created"}),
            dataIndex: 'createDate',
            width: "fit-content",
            render: (text, record) => {
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                return <span>{moment(record.createDate).tz(timeZone).format("DD/MM/YYYY  HH:mm:ss")}</span>
            }

        },
        {
            title: intl.formatMessage({id: "type"}),
            dataIndex: 'type',
            width: "fit-content",
            render: (text, record) => {
                return <span><FormattedMessage id={TransactionType[record.type]}/></span>
            }

        },
        {
            title: intl.formatMessage({id: "status"}),
            dataIndex: 'status',
            width: "fit-content",
            render: (text, record) => {
                return <span className={TransactionStatus[record.status]}><FormattedMessage
                    id={TransactionStatus[record.status]}/></span>
            }

        },
        {
            title: intl.formatMessage({id: "bet_id"}),
            dataIndex: 'betId',
            width: "fit-content",
            render: (text, record) => {
                return <div className="copyable" onDoubleClick={(e) => {
                    copyLabel(e, record.betId)
                        .then(() => {
                            setDoneMsg("text_is_copied")
                        }).catch(() => {
                        setErrorMsg("something_wrong_the_text_is_not_copied")
                    })
                }}>{record.betId}</div>
            }
        },
        {
            title: intl.formatMessage({id: "amount"}),
            dataIndex: 'amount',
            width: "fit-content"
        },
        {
            title: intl.formatMessage({id: "amount_after"}),
            dataIndex: 'amountAfter',
            width: "fit-content"
        },

    ];

    useEffect(() => {
        if (transactionsList && transactionsList.items) {
            setTotalPages(transactionsList.total);
        }
    }, [transactionsList]);




    const fetchTransactions = (isFirst) => {
        let initialForm = getUrlValue(search, keysOfDate)
        initialForm = {
            ...initialForm,
            page : initialForm.page || page,
            limit: initialForm.limit || limit
        }
        if(Object.keys(initialForm).length) {
            getTransactionsList(initialForm)
            const {params} = getUrlString(initialForm)
            isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()})
        }
    }

    useEffect(() => {
        fetchTransactions(true)
    },[])

    useEffect(() => {
        return history.listen((location) => {
            const loc = location.search
            if (loc) {
                const initialForm = getUrlValue(loc, keysOfDate)
                getTransactionsList(initialForm)
                setPage(+initialForm.page || page)
                setLimit(+initialForm.limit || limit)
            }
        })
    },[history])


    const handleTablePaginate = (changedPage, changedLimit) => {
        let newPage = 1;
        if (limit === changedLimit) newPage = changedPage
        setPage(newPage);
        setLimit(changedLimit)
        const initialForm = getUrlValue(search, keysOfDate)
        const {params} = getUrlString({...initialForm, page: newPage, limit: changedLimit})
        history.push({search: params.toString()})

    }


    return (
        <div className="content transactions">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='transactions'/></div>
                <button className={classNames("filterBtn", {show: showFilter})} onClick={() => setShowFilter(prev => !prev)}>
                    <div className="icon"/>
                    <FormattedMessage id='filter'/>
                </button>
            </div>
            <TransactionsFilter limit={limit} setPage={setPage} showFilter={showFilter} />
            <div className="transactions">
                <div className="mainTable">
                    {loading._transactions_list ? <TableLoader count={limit} column={12} icon={false} drag={false} actions={0}/> :
                        <div>
                            <Table dataSource={transactionsList.items || []}
                                   columns={columns}
                                   rowKey="id"
                                   scroll={{x: 'calc(100vw)'}}
                                   pagination = {{
                                       total: totalPages,
                                       showSizeChanger: true,
                                       showLessItems: true,
                                       onChange: (page, limit) => handleTablePaginate(page, limit),
                                       showTotal: (total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`,
                                       defaultPageSize: limit,
                                       defaultCurrent: page
                                   }}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        transactionsList: state.transactionsList,
        loading: state.loading,
    }
}

const mapDispatchToProps = {
    getTransactionsList,
    setErrorMsg,
    setDoneMsg
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Transactions));