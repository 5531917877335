import React, {useEffect, useState} from "react";
import {getTransactionsFilter, getTransactionsCsvLink, cancelTransactionCsvDownload} from "@actions/transactionsActions";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {DatePicker, Form, Input, Select, Tooltip} from "antd";
import {TransactionStatus, TransactionType} from "@constants/Enums";
import {getUrlString, getUrlValue} from "../../utils/CommonUtils";
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import {getFormattedForForm, getFormattedBody} from "@services/TableServices";
import moment from "moment-timezone";
import iconDown from "@assets/img/icon/iconDown.svg";
import CsvDownloader from "@components/CSVDownloader/CsvDownloader";

function TransactionsFilter({filter, intl , limit, getTransactionsFilter, setPage, showFilter, getTransactionsCsvLink, loading, cancelTransactionCsvDownload, allowCancel}) {
    let history = useHistory()
    const [form] = Form.useForm();
    const [clientSelect, setClientSelect] = useState([]);
    const [statusSelect, setStatusSelect] = useState([]);
    const [typeSelect, setTypeSelect] = useState([]);


    useEffect(() => { getTransactionsFilter() }, []);



    useEffect(() => {
        if (Object.keys(filter).length) {
            setClientSelect(filter.clientSelect || []);
            setTypeSelect(filter.transactionTypesSelect);
            setStatusSelect(filter.transactionStatusSelect);
        }
    }, [filter])

    useEffect(() => {
        return history.listen((location) => {
            const loc = history && history.location && history.location.search
            let initialForm = getUrlValue(loc);

            if (initialForm.createdFrom || initialForm.createdTo) {
                initialForm.created = [moment(initialForm["createdFrom"]) || "", moment(initialForm["createdTo"]) || ""]
            }

            form.resetFields()
            form.setFieldsValue(getFormattedForForm(initialForm))
        })
    },[history])

    const finishFilter = (data) => {
        const queryObj = {...getFormattedBody(data),page : 1, limit}

        if (data.created && data.created.length) {
            queryObj["createdFrom"] =  data.created[0].toString()
            queryObj["createdTo"] =  data.created[1].toString()
        }
        delete queryObj["created"]

        const {params} = getUrlString(queryObj)
        setPage(1)

        history.push({search: params.toString()})
    }

    const resetFilter = () => {
        const initialObj = {start: "", end: ""}
        form.setFieldsValue({
            clientId:"",
            statuses: [],
            types: [],
            created: initialObj,
            amountFrom: "",
            amountTo: "",
            playerId: "",
            betId: "",
            sessionId: "",
            transactionId:"",
        })
    }

    const exportCVS = () => {
        if (loading._transactions_csv_downloader_url === undefined || !loading._transactions_csv_downloader_url) {
            getTransactionsCsvLink(form.getFieldsValue())
        }
    }

    return (
        <div className="searchBar csv">
            <Form
                form={form}
                onFinish={finishFilter}
                layout="vertical"
                validateTrigger="onBlur">
                <div className={classNames("searchBar--row",{show: showFilter})}>
                    <div className="searchBar--col">
                        <Form.Item
                            name="transactionId"
                            label={<FormattedMessage id={"transactionId"}/>} required={false}
                            rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)
                                        if (value && !ipRegExp)
                                        {
                                            return Promise.reject(intl.formatMessage({id: `value_must_be_GUID`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_transactionId"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            name="sessionId"
                            label={<FormattedMessage id={"session_id"}/>} required={false}
                            rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)
                                        if (value && !ipRegExp)
                                        {
                                            return Promise.reject(intl.formatMessage({id: `value_must_be_GUID`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_sessionId"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            label={<FormattedMessage id={"client"}/>} name="clientId">
                            <Select allowClear
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    suffixIcon={<img src={iconDown} />}>
                                {clientSelect.map(client => (
                                    <Select.Option key={client.id} value={client.id}>{client.name}</Select.Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            label={<FormattedMessage id={"player_id"}/>} name="playerId">
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_player_id"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            name="betId"
                            label={<FormattedMessage id={"bet_id"}/>} required={false}
                            rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)
                                        if (value && !ipRegExp)
                                        {
                                            return Promise.reject(intl.formatMessage({id: `value_must_be_GUID`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_bet_id"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item className={"numberRangeInput"} label={<FormattedMessage id={"amount"}/>}>
                            <Form.Item name="amountFrom" noStyle  rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_from_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "from"})}/>
                            </Form.Item>
                            <div className="separator"/>
                            <Form.Item name="amountTo" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_to_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "to"})}/>
                            </Form.Item>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"type"}/>} name="types">
                            <Select mode="multiple"
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    optionLabelProp="label"
                                    showArrow
                                    showSearch={false}
                                    suffixIcon={<img src={iconDown} />}>
                                {typeSelect.map(type => (
                                    <Select.Option key={type} value={""+type}
                                                   label={<FormattedMessage id={TransactionType[type]}/>}>
                                        <FormattedMessage id={TransactionType[type]}/>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"status"}/>} name="statuses">
                            <Select mode="multiple" optionLabelProp="label" showArrow showSearch={false}
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    suffixIcon={<img src={iconDown} />}>
                                {statusSelect.map(status => (
                                    <Select.Option key={status} value={""+status}
                                                   label={<FormattedMessage id={TransactionStatus[status]}/>}>
                                        <FormattedMessage id={TransactionStatus[status]}/>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"created_date"}/>}
                                   name="created">
                            <DatePicker.RangePicker getPopupContainer={triggerNode => triggerNode.parentElement} showTime/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col btnBox">
                        <div className="filterPart">
                            <button type="submit" className="miniBtn small">
                                <FormattedMessage id='search'/>
                            </button>
                            <button type="button" className="miniBtn default small" onClick={resetFilter}>
                                <FormattedMessage id='clear'/>
                            </button>
                        </div>
                        <div className="exportPart">
                            <CsvDownloader exportCVS={exportCVS}
                                           downloadLoading={loading._transactions_csv_downloader_url}
                                           cancelLoading={loading._cancel_transactions_csv_download}
                                           onCancel={cancelTransactionCsvDownload}
                                           allowCancel={allowCancel}/>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        filter: state.transactionsFilter,
        loading: state.loading,
        allowCancel: state.csvAllowCancel.allowTransaction
    }
}

const mapDispatchToProps = {
    getTransactionsFilter,
    getTransactionsCsvLink,
    cancelTransactionCsvDownload
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TransactionsFilter));