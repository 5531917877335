import React, {useEffect, useState} from "react";
import {getBetListFilter} from "@actions/betsActions";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Form, Input, Select} from "antd";
import {getUrlString, getUrlValue} from "../../utils/CommonUtils";
import {useHistory} from "react-router-dom";
import {getFormattedForForm, getFormattedBody} from "@services/TableServices";
import iconDown from "@assets/img/icon/iconDown.svg";

function ActiveBetsFilter({filter, intl, showFilter, getBetListFilter}) {
    let history = useHistory()
    const [form] = Form.useForm();
    const [clientSelect, setClientSelect] = useState([]);

    const initFormData = (loc) => {
        let initialForm = getUrlValue(loc);

        form.resetFields();
        form.setFieldsValue(getFormattedForForm(initialForm));
    }

    useEffect(() => { getBetListFilter() }, []);


    useEffect(() => {
        if (filter && filter.clientSelect) {
            setClientSelect(filter.clientSelect || []);
        }
    }, [filter])

    useEffect(() => {
        return history.listen((location) => {
            const loc = history && history.location && history.location.search
            initFormData(loc)
        })
    },[history])

    useEffect(() => {
        const loc = history && history.location && history.location.search
        initFormData(loc)
    }, [])

    const finishFilter = (data) => {
        const {params} = getUrlString( {...getFormattedBody(data)})
        history.push({search: params.toString()})
    }

    const resetFilter = () => {
         form.setFieldsValue({
             clientId:"",
             playerNameLike:"",
             stackFrom: "",
             stackTo: "",
        })
    }



    return (
        <div className="searchBar ">
            <Form
                form={form}
                onFinish={finishFilter}
                layout="vertical"
                validateTrigger="onBlur">
                <div className="searchBar--row static">
                    <div className="searchBar--col">
                        <Form.Item
                            label={<FormattedMessage id={"client"}/>} name="clientId">
                            <Select allowClear
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    suffixIcon={<img src={iconDown} />}>
                                {clientSelect.map(client => (
                                    <Select.Option key={client.id} value={client.id}>{client.name}</Select.Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            name="playerNameLike"
                            label={<FormattedMessage id={"player_name"}/>}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_player_name"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item className={"numberRangeInput"} label={<FormattedMessage id={"stake"}/>}>
                            <Form.Item name="stackFrom" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_from_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "from"})}/>
                            </Form.Item>
                            <div className="separator"/>
                            <Form.Item name="stackTo" noStyle  rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_to_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "to"})}/>
                            </Form.Item>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col btnBox">
                        <button type="submit" className="miniBtn small">
                            <FormattedMessage id='search'/>
                        </button>
                        <button type="button" className="miniBtn default small" onClick={resetFilter}>
                            <FormattedMessage id='clear'/>
                        </button>
                    </div>
                </div>
            </Form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        filter: state.betsListFilter,
    }
}

const mapDispatchToProps = {
    getBetListFilter
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ActiveBetsFilter));