import React, {useEffect, useState, useContext} from "react";
import {connect} from "react-redux";
import {
    addClient,
    editClient,
} from "@actions/clientsActions";
import {
    REQUEST_ADD_CLIENT,
    REQUEST_EDIT_CLIENT
} from "@actions/actionTypes";

import { Modal, Form, Input, Row, Col, Switch, Select, Radio, Tabs, Button, Space, Tooltip } from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import {GameType, GameTemplateCurrency, PlayersCount} from "@constants/Enums";
import Infinite from "react-infinite";
import classNames from "classnames";
const botDices = ["botDice1", "botDice2", "botDice3", "botDice4", "botDice5", "botDice6"];
const playerDices = ["playerDice1", "playerDice2", "playerDice3", "playerDice4", "playerDice5", "playerDice6"];
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import iconDown from "@assets/img/icon/iconDown.svg";
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import USDIcon from "@assets/img/currency/USD.svg";
import GBPIcon from "@assets/img/currency/GBP.svg";
import INRIcon from "@assets/img/currency/INR.svg";
import HKDIcon from "@assets/img/currency/HKD.svg";
import AEDIcon from "@assets/img/currency/AED.svg";
import ModalLoader from "@components/Loaders/modalLoader";

const currencyIcons = {
    USD: USDIcon,
    GBP: GBPIcon,
    INR: INRIcon,
    HKD: HKDIcon,
    AED: AEDIcon
}
const includedNames = ["Snake", "Ladder", "Finish"]

const isIncluded = (target) => {
    let isIncluded = false
    includedNames.some(function(word){
        isIncluded = target.indexOf(word) > -1
       return target.indexOf(word) > -1;
    });
    return isIncluded
}

const defaultCurrencies =  [
    {
        currency: undefined,
        name: undefined,
        playerCount: 5,
        stake: undefined,
        win: undefined
    }
]


function ClientControlPopup({handleCancel, show, intl, addClient, client, editClient, loading}) {
    const [form] = Form.useForm();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    const [tabMode, setTabMode] = useState("1");
    const [botSettingsErr, setBotSettingsErr] = useState(["", ""])
    const [firstTabError, setFirstTabError] = useState(false)
    const [secondTabValid, setSecondTabValid] = useState(false)
    const [threeTabValid, setThreeTabValid] = useState(false)
    const [isFirstOpen, setIsFirstOpen] = useState(!client?.id)

    useEffect(() => {
        if (client.id) {
            subscribeForError(REQUEST_EDIT_CLIENT, () => {
                handleCancel();
            });
        } else {
            subscribeForError(REQUEST_ADD_CLIENT, () => {
                handleCancel();
            });
        }
        return () => {
            unSubscribeForError(REQUEST_ADD_CLIENT);
            unSubscribeForError(REQUEST_EDIT_CLIENT);
        }
    }, [])

    useEffect(() => {
        form.resetFields()
        if (client.id) {
            const formValue = {...client,  ...client.config, ...client.diceProbabilitySettings}
            if (!(client.gameTemplates && client.gameTemplates[0])) {
                formValue.gameTemplates = [...defaultCurrencies]
            }
            form.setFieldsValue(formValue)
        } else {
            const formValue = {
                "playerSnake": 0.16,
                "playerLadder": 0.16,
                "playerFinish": 0.16,
                "botSnake": 0.16,
                "botLadder": 0.16,
                "botFinish": 0.16
            }
            for (let i = 1; i <= 6; i++) {
                formValue["botDice"+i] = 0.16
                formValue["playerDice"+i] = 0.16
            }
            form.setFieldsValue({...formValue, gameTemplates: defaultCurrencies})
        }
    }, [client])


    const onFinish = (data) => {
        const body ={config:{},diceProbabilitySettings:{...client.diceProbabilitySettings}, gameTemplates: []}
        Object.keys(data).forEach(item => {
            if(item === "onlyBots" || item === "isUnderMaintenance") {
                body["config"][item] = data[item]
            } else if(isIncluded(item) || item.indexOf("Dice") > -1 ) {
                body["diceProbabilitySettings"][item] = +data[item]
            }
            else {
                body[item] = data[item]
            }
        })
        if (!(botSettingsErr[0].length || botSettingsErr[1].length)) {
            if (client.id) {
                editClient({...body, id: client.id, name: client.name})
            } else {
                addClient(body)
            }
        }

    }

    const handleModeChange = (e) => {
        const mode = e.target.value;
        if (mode === "2" || mode === "3") {
            setIsFirstOpen(false)
        }
        setTabMode(mode);
    }

    useEffect( () => {
        if (isFirstOpen) {
            return
        }
        const values = form.getFieldsValue();
         form.validateFields([...Object.keys(values)],{force: true}).then(() => formValueChange())
    }, [tabMode])


    const formValueChange = () => {
        let firstTabValid = false;
        let secondTabValid = false;
        let threeTabValid = false;
        setFirstTabError(false);
        setSecondTabValid(false);
        setThreeTabValid(false);

        let values = form.getFieldsValue();
        validateBotDices(values).then(() => {
            setBotSettingsErr(prev => [prev[0], ''])
        }).catch((err) => {
            setBotSettingsErr(prev => [prev[0], err])
        })

        validatePlayerDices(values).then(() => {
            setBotSettingsErr(prev => [prev[0], ''])
        }).catch((err) => {
            setBotSettingsErr(prev => [prev[0], err])
        })


        form.getFieldsError().some((field) => {
            if(field.name[0].indexOf("Dice") > -1 || isIncluded(field.name[0]) ) {
                if (isIncluded(field.name[0])) {
                    if (field.errors.length > 0) {
                        setBotSettingsErr(prev => [field.errors[0], prev[1]])
                    } else {
                        setBotSettingsErr(prev => prev[0] != ''? ['', prev[1]] : prev)
                    }
                }
                secondTabValid = field.errors.length > 0
                return  field.errors.length > 0
            } else if (field.name.includes("gameTemplates")) {
                threeTabValid =field.errors.length > 0
                return  field.errors.length > 0
            } else{
                firstTabValid = field.errors.length > 0
                return  field.errors.length > 0
            }
        })
        setBotSettingsErr(prev => !secondTabValid ? ['', ''] : prev)
        setFirstTabError(firstTabValid)
        setSecondTabValid(secondTabValid)
        setThreeTabValid(threeTabValid)
    }

    const validateDices = (values, fildsName, translationName) => {
        let dicesAmount = 0

        const valueRegExp = new RegExp(/^\d+(\.\d+)?$/);
        const afterDotRegExp = new RegExp(/^\d+(\.\d{0,2})?$/)
        try {
            fildsName.forEach((key) => {
                let value = values[key]
                if (!valueRegExp.test(value)) {
                    throw intl.formatMessage({id: translationName}) + " " + intl.formatMessage({id: `dice_validator`})
                }

                if (!afterDotRegExp.test(value)) {
                    throw intl.formatMessage({id: translationName}) + " " + intl.formatMessage({id: `after_dot_must_be_two_digit`})
                }

                if (value &&  (value > 1 || value < 0 )) {
                    throw intl.formatMessage({id: translationName}) + " " + intl.formatMessage({id: `dice_is_not_valid`})
                }
            })
        } catch(message) {
            return Promise.reject(message)
        }

        fildsName.forEach(dice => {
            dicesAmount = Number((dicesAmount + +values[dice]).toFixed(6))
        })
        
        if (dicesAmount !== 1) {
            return Promise.reject(intl.formatMessage({id: translationName}) + " " + intl.formatMessage({id: `dices_amount_is_not_valid`}));
        }
        return Promise.resolve();
    }

    const  validateBotDices = (values) => {
        return validateDices(values, botDices, `bot`)
    }

    const  validatePlayerDices = (values) => {
        return validateDices(values, playerDices, `player`)
    }

    const validateToken = () => {
        return {
            validator(_, value) {
                const afterDotRegExp = new RegExp(/^\d+(\.\d{0,2})?$/)
                const valueRegExp = new RegExp(/^\d+(\.\d+)?$/);
                if (!valueRegExp.test(value)) {
                    setBotSettingsErr(prev => {
                        prev[0] = intl.formatMessage({id: `token_validator`})
                        return prev
                    })
                    return Promise.reject(intl.formatMessage({id: `token_validator`}))
                }

                if (!afterDotRegExp.test(value)) {
                    return Promise.reject(intl.formatMessage({id: `after_dot_must_be_two_digit`}))
                }
                if (value &&  (value > 1 || value < 0 )) {
                    setBotSettingsErr(prev => {
                        prev[0] = intl.formatMessage({id: `token_is_not_valid`})
                        return prev
                    })
                    return Promise.reject(intl.formatMessage({id: `token_is_not_valid`}));
                }
                return Promise.resolve("");
            }
        }
    }

    const enterValidNumber = (value, name) => {
        const numberRegExp = new RegExp(/^(?:[1-9][0-9]*|0)$/);
        let formValue = form.getFieldsValue()
        if (numberRegExp.test(value)) {
            if (value != 0) {
                formValue[name[0]][name[1]][name[2]] = value

            } else {
                formValue[name[0]][name[1]][name[2]] = value.substring(0, value.length - 1)
            }
        } else {
            formValue[name[0]][name[1]][name[2]] = value.substring(0, value.length - 1)
        }

        form.setFieldsValue(formValue)
    }

    const enterValidDecimalNumber = (value, name) => {
        const numberRegExp = new RegExp(/^([0-9]+(\.?[0-9]?[0-9]?)?)$/g);
        let formValue = form.getFieldsValue()
        if (numberRegExp.test(value)) {
            formValue[name[0]][name[1]][name[2]] = value
            const afterDot = value.split(".")[0]
            const beforeDot = value.split(".")[1]
            if (/\b(0)+[1-9]\d*/g.test(afterDot)) {
                const val = afterDot.replace(/\b(0)+/g, '')
                formValue[name[0]][name[1]][name[2]] = val
            } else {
                if (/\b(0)+[.]\d*/g.test(value)) {
                    const val = afterDot.replace(/\b(0)+/g, '') + "." + beforeDot;
                    formValue[name[0]][name[1]][name[2]] = "0" + val
                }
            }
        } else {
            formValue[name[0]][name[1]][name[2]] = value.substring(0, value.length - 1)
        }

        form.setFieldsValue(formValue)
    }

    return (
        <Modal
            title={client.id ? <FormattedMessage id='edit_client'/> : <FormattedMessage id='add_client'/> }
            centered
            className={classNames(`formModal`, {loading: loading._single_client})}
            visible={show}
            width={596}
            onCancel={() => handleCancel()}
            footer={false}>
            {
                loading._single_client ?
                    <ModalLoader tabs={true} /> :
                    <>
                        <div className="tabMode centeredBox">
                            <Radio.Group onChange={handleModeChange} value={tabMode} className={classNames({invalid: firstTabError || botSettingsErr[0].length || botSettingsErr[1].length || threeTabValid})}>
                                <Radio.Button className={classNames("centeredBox", {invalid: firstTabError})} value="1"><FormattedMessage id={'details'}/></Radio.Button>
                                <Radio.Button className={classNames("centeredBox", {invalid: botSettingsErr[0].length || botSettingsErr[1].length})} value="2"><FormattedMessage
                                    id={'bot_settings'}/></Radio.Button>
                                <Radio.Button className={classNames("centeredBox", {invalid: threeTabValid})} value="3"><FormattedMessage
                                    id={'game_templates'}/></Radio.Button>
                            </Radio.Group>
                        </div>
                        <Form
                            layout="vertical"
                            form={form}
                            name="trading-form"
                            style={{position: "relative"}}
                            onFinish={onFinish}
                            onFieldsChange={formValueChange}
                            className="clientsForm"
                            scrollToFirstError
                            validateTrigger="onBlur"
                            wrapperCol={false}
                        >
                            <div className="overflowContent">
                                <Tabs activeKey={tabMode}>
                                    <Tabs.TabPane key="1">
                                        <Row className="MainModal">
                                            <Col span={24} className="MainModal--parts">
                                                {client.id ?
                                                    <Form.Item label={<FormattedMessage id='name'/>}>
                                                        {client.name}
                                                    </Form.Item>
                                                    :
                                                    <Form.Item label={<FormattedMessage id='name'/>}
                                                               rules={[
                                                                   {
                                                                       required: true,
                                                                       message: <FormattedMessage id='name_validator'/>
                                                                   },
                                                                   ({getFieldValue}) => ({
                                                                       validator(_, value) {
                                                                           const nameRegExp = new RegExp(/^[\w|_| ]+$/)

                                                                           if (value && !(value.length >= 3 && value.length <= 256))
                                                                           {
                                                                               return Promise.reject(intl.formatMessage({id: `name_valid`}))
                                                                           }

                                                                           if (value && !nameRegExp.test(value)) {
                                                                               return Promise.reject(intl.formatMessage({id: `name_must_not_contain_special_characters`}))
                                                                           }

                                                                           return Promise.resolve();
                                                                       },
                                                                   })
                                                               ]}
                                                               name={"name"}>
                                                        <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_name"})}/>
                                                    </Form.Item>


                                                }
                                                <Form.Item label={<FormattedMessage id='secret'/>}

                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: <FormattedMessage id='secret_validator'/>
                                                               },
                                                               ({getFieldValue}) => ({
                                                                   validator(_, value) {

                                                                       if (value && value.length > 255)
                                                                       {
                                                                           return Promise.reject(intl.formatMessage({id: `client_secret_valid`}))

                                                                       }
                                                                       return Promise.resolve();
                                                                   },
                                                               })
                                                           ]}
                                                           name={"secret"}
                                                >
                                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_secret"})}/>
                                                </Form.Item>
                                                <Form.Item label={<FormattedMessage id='bet_url'/>}

                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: <FormattedMessage id='bet_url_validator'/>
                                                               },
                                                               ({getFieldValue}) => ({
                                                                   validator(_, value) {
                                                                       const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                       if (value && !urlPattern.test(value)) {
                                                                           return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                       }
                                                                       return Promise.resolve();
                                                                   },
                                                               })
                                                           ]}
                                                           name={"betCallback"}
                                                >
                                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_bet_url"})}/>
                                                </Form.Item>
                                                <Form.Item label={<FormattedMessage id='result_url'/>}

                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: <FormattedMessage id='result_url_validator'/>
                                                               },
                                                               ({getFieldValue}) => ({
                                                                   validator(_, value) {
                                                                       const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                       if (value && !urlPattern.test(value)) {
                                                                           return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                       }
                                                                       return Promise.resolve();
                                                                   },
                                                               })
                                                           ]}
                                                           name={"resultCallback"}
                                                >
                                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_result_url"})}/>
                                                </Form.Item>
                                                <Form.Item label={<FormattedMessage id='rollback_url'/>}

                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: <FormattedMessage id='rollback_url_validator'/>
                                                               },
                                                               ({getFieldValue}) => ({
                                                                   validator(_, value) {
                                                                       const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                       if (value && !urlPattern.test(value)) {
                                                                           return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                       }
                                                                       return Promise.resolve();
                                                                   },
                                                               })
                                                           ]}
                                                           name={"refundCallback"}
                                                >
                                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_rollback_url"})}/>
                                                </Form.Item>
                                                <Form.Item label={<FormattedMessage id='balance_url'/>}

                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: <FormattedMessage id='balance_url_validator'/>
                                                               },
                                                               ({getFieldValue}) => ({
                                                                   validator(_, value) {
                                                                       const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                       if (value && !urlPattern.test(value)) {
                                                                           return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                       }
                                                                       return Promise.resolve();
                                                                   },
                                                               })
                                                           ]}
                                                           name={"balanceCallback"}
                                                >
                                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_balance_url"})}/>
                                                </Form.Item>

                                                <Form.Item
                                                    label={<FormattedMessage id='only_bots'/>}

                                                    className="formHorizontal"
                                                    name="onlyBots"
                                                    valuePropName="checked">
                                                    <Switch className="radioSwitch"/>
                                                </Form.Item>
                                                <Form.Item
                                                    label={<FormattedMessage id='is_under_maintenance'/>}

                                                    className="formHorizontal"
                                                    name="isUnderMaintenance"
                                                    valuePropName="checked">
                                                    <Switch className="radioSwitch"/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key="2" forceRender={true}>
                                        <div className="inputTable token-inputs">
                                            <table style={{width: "100%"}}>
                                                <tbody>
                                                <tr>
                                                    <td/>
                                                    <td><FormattedMessage id={'token_snake'}/></td>
                                                    <td><FormattedMessage id={'token_ladder'}/></td>
                                                    <td><FormattedMessage id={'token_finish'}/></td>
                                                </tr>
                                                <tr>
                                                    <td><FormattedMessage id={'player'}/></td>
                                                    <td>
                                                        <Form.Item rules={[() => validateToken()]} name={"playerSnake"} className="disableError">
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "token_snake"})}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item rules={[() => validateToken()]} name={"playerLadder"} className="disableError">
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "token_ladder"})}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item rules={[() => validateToken()]} name={"playerFinish"} className="disableError">
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "token_finish"})}/>
                                                        </Form.Item>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><FormattedMessage id={'bot'}/></td>
                                                    <td>
                                                        <Form.Item rules={[() => validateToken()]} name={"botSnake"} className="disableError">
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "token_snake"})}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item rules={[() => validateToken()]} name={"botLadder"} className="disableError">
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "token_ladder"})}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item rules={[() => validateToken()]} name={"botFinish"} className="disableError">
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "token_finish"})}/>
                                                        </Form.Item>
                                                    </td>
                                                </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <div className="inputTable dice-inputs">
                                            <table style={{width: "100%"}}>
                                                <tbody>
                                                <tr>
                                                    <td/>
                                                    <td><FormattedMessage id={'dice'}/> 1</td>
                                                    <td><FormattedMessage id={'dice'}/> 2</td>
                                                    <td><FormattedMessage id={'dice'}/> 3</td>
                                                    <td><FormattedMessage id={'dice'}/> 4</td>
                                                    <td><FormattedMessage id={'dice'}/> 5</td>
                                                    <td><FormattedMessage id={'dice'}/> 6</td>
                                                </tr>
                                                <tr>
                                                    <td><FormattedMessage id={'bot'}/></td>
                                                    <td>
                                                        <Form.Item name={"botDice1"} className="disableError"
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage id='dice_required'/>
                                                                       }
                                                                   ]}>
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "dice"}) + "1"}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item name={"botDice2"} className="disableError"
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage id='dice_required'/>
                                                                       }
                                                                   ]}>
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "dice"}) + "2"}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item name={"botDice3"} className="disableError"
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage id='dice_required'/>
                                                                       }
                                                                   ]}>
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "dice"}) + "3"}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item  name={"botDice4"} className="disableError"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: <FormattedMessage id='dice_required'/>
                                                                        }
                                                                    ]}>
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "dice"}) + "4"}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item  name={"botDice5"} className="disableError"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: <FormattedMessage id='dice_required'/>
                                                                        }
                                                                    ]}>
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "dice"}) + "5"}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item  name={"botDice6"} className="disableError"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: <FormattedMessage id='dice_required'/>
                                                                        }
                                                                    ]}>
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "dice"}) + "6"}/>
                                                        </Form.Item>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><FormattedMessage id={'player'}/></td>
                                                    <td>
                                                        <Form.Item name={"playerDice1"} className="disableError"
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage id='dice_required'/>
                                                                       }
                                                                   ]}>
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "dice"}) + "1"}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item name={"playerDice2"} className="disableError"
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage id='dice_required'/>
                                                                       }
                                                                   ]}>
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "dice"}) + "2"}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item name={"playerDice3"} className="disableError"
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage id='dice_required'/>
                                                                       }
                                                                   ]}>
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "dice"}) + "3"}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item  name={"playerDice4"} className="disableError"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: <FormattedMessage id='dice_required'/>
                                                                        }
                                                                    ]}>
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "dice"}) + "4"}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item name={"playerDice5"} className="disableError"
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage id='dice_required'/>
                                                                       }
                                                                   ]}>
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "dice"}) + "5"}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item name={"playerDice6"} className="disableError"
                                                                   rules={
                                                                       [
                                                                           {
                                                                               required: true,
                                                                               message: <FormattedMessage
                                                                                   id='dice_required'/>
                                                                           }
                                                                       ]}>
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "dice"}) + "6"}/>
                                                        </Form.Item>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key="3" >
                                        <Form.List name="gameTemplates">
                                            {(fields, { add, remove }) => (
                                                <>
                                                <Infinite containerHeight={400}
                                                          elementHeight={fields.map((_, index) => index === 0 ? 75 : 48)}>
                                                    {fields.map(({ key, name, fieldKey, ...restField }) =>
                                                            <Space key={key} className={`defaultGames ${name === 0 ? "firstChild" : ""}`}>
                                                                    <Form.Item
                                                                        className="without-star"
                                                                        label={name === 0 ? <FormattedMessage id='currency_name'/> : undefined}
                                                                        {...restField}
                                                                        name={[name, 'currency']}
                                                                        fieldKey={[fieldKey, 'currency']}
                                                                        rules={
                                                                            [
                                                                                {
                                                                                    required: true,
                                                                                    message: <FormattedMessage id='currency_is_required'/>
                                                                                }
                                                                            ]
                                                                        }
                                                                    >
                                                                        <Select placeholder={<FormattedMessage id="select"/>}
                                                                                optionLabelProp="label"
                                                                                dropdownMatchSelectWidth={false}
                                                                                className="gameTemplateCurrencySelect">
                                                                            {
                                                                                GameTemplateCurrency.map(currency => (
                                                                                    <Select.Option value={currency}
                                                                                                   key={currency}
                                                                                                   label={
                                                                                                       <>
                                                                                                           <img src={currencyIcons[currency]} className="icon"/>
                                                                                                           <FormattedMessage id={`${currency}_short`}/>
                                                                                                       </>
                                                                                                   }>
                                                                                        <img src={currencyIcons[currency]} className="icon"/>
                                                                                        <FormattedMessage id={currency}/>
                                                                                    </Select.Option>
                                                                                ))
                                                                            }

                                                                        </Select>
                                                                    </Form.Item>
                                                                <Form.Item
                                                                    {...restField}
                                                                    className="without-star"
                                                                    label={name === 0 ? <FormattedMessage id='name'/> : undefined}
                                                                    name={[name, 'name']}
                                                                    fieldKey={[fieldKey, 'name']}
                                                                    rules={
                                                                        [
                                                                            {
                                                                                required: true,
                                                                                message: <FormattedMessage
                                                                                    id='name_is_required'/>
                                                                            },
                                                                            ({getFieldValue}) => ({
                                                                                validator(_, value) {
                                                                                    const nameRegExp = new RegExp(/^[\w|_| ]+$/)

                                                                                    if (value && !(value.length >= 3 && value.length <= 256)) {
                                                                                        return Promise.reject(intl.formatMessage({id: `name_valid`}))
                                                                                    }
                                                                                    if (value && !nameRegExp.test(value)) {
                                                                                        return Promise.reject(intl.formatMessage({id: `name_must_not_contain_special_characters`}))
                                                                                    }

                                                                                    return Promise.resolve();
                                                                                },
                                                                            })
                                                                        ]
                                                                    }
                                                                >
                                                                    <Input autoComplete="off"
                                                                           placeholder={intl.formatMessage({id: "type_name"})}/>
                                                                </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        className="without-star"
                                                                        label={name === 0 ? <FormattedMessage id='stake'/> : undefined}
                                                                        name={[name, 'stake']}
                                                                        fieldKey={[fieldKey, 'stake']}
                                                                        rules={
                                                                            [
                                                                                {
                                                                                    required: true,
                                                                                    message: <FormattedMessage id='stake_is_required'/>
                                                                                },
                                                                                ({getFieldValue}) => ({
                                                                                    validator(_, value) {

                                                                                        if (value == 0) {
                                                                                            return Promise.reject(intl.formatMessage({id: `stake_win_must_be_number`}));
                                                                                        }

                                                                                        return Promise.resolve();
                                                                                    },
                                                                                })
                                                                            ]
                                                                        }
                                                                    >
                                                                        <Input autoComplete="off"
                                                                               onChange={({target}) => enterValidDecimalNumber(target.value, ["gameTemplates", name, 'stake'])}
                                                                               placeholder={intl.formatMessage({id: "type_stake"})} />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        className="without-star"
                                                                        label={name === 0 ? <FormattedMessage id='win'/> : undefined}
                                                                        {...restField}
                                                                        name={[name, 'win']}
                                                                        fieldKey={[fieldKey, 'win']}
                                                                        rules={
                                                                            [
                                                                                {
                                                                                    required: true,
                                                                                    message: <FormattedMessage id='win_is_required'/>
                                                                                },
                                                                                ({getFieldValue}) => ({
                                                                                    validator(_, value) {

                                                                                        if (value == 0) {
                                                                                            return Promise.reject(intl.formatMessage({id: `win_must_be_number`}));
                                                                                        }

                                                                                        return Promise.resolve();
                                                                                    },
                                                                                })
                                                                            ]
                                                                        }
                                                                    >
                                                                        <Input autoComplete="off"
                                                                               onChange={({target}) => enterValidDecimalNumber(target.value, ["gameTemplates", name, 'win'])}
                                                                               placeholder={intl.formatMessage({id: "type_win"})}/>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        className="without-star"
                                                                        {...restField}
                                                                        label={name === 0 ? <FormattedMessage id='player_count'/> : undefined}
                                                                        name={[name, 'playerCount']}
                                                                        initialValue={5}
                                                                        fieldKey={[fieldKey, 'playerCount']}
                                                                        rules={
                                                                            [
                                                                                {
                                                                                    required: true,
                                                                                    message: <FormattedMessage id='player_count_is_required'/>
                                                                                }
                                                                            ]
                                                                        }
                                                                    >
                                                                        <Select placeholder={intl.formatMessage({id: "type_players_count"})}
                                                                                optionLabelProp="label"
                                                                                dropdownMatchSelectWidth={false}
                                                                                autoComplete="off">
                                                                            {PlayersCount.map(item => (
                                                                                <Select.Option value={item}
                                                                                               key={item}
                                                                                               label={item}>
                                                                                    {item}
                                                                                </Select.Option>))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                    <MinusOutlined className={classNames("iconBox ", {disabled: fields.length === 1})} onClick={() =>{
                                                                        if(fields.length !== 1) {
                                                                            remove(name);
                                                                            setTimeout(() => formValueChange(), 10)
                                                                        }
                                                                    }} disabled={fields.length === 1} />
                                                                </Space>
                                                    )}
                                                </Infinite>
                                                    <Form.Item className="add-button">
                                                        <Tooltip placement="left" title={<FormattedMessage id="add_currency"/>}>
                                                            <PlusOutlined
                                                                onClick={() => add()}/>
                                                        </Tooltip>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>

                            <Form.Item className="MainModal--footer">
                                <div className={`d-flex ${tabMode === "3"  ? "justify-end" : "justify-between"} centeredBox w-100`}>
                                    {
                                        tabMode === "3" ? "" :
                                            <div className="color_error">
                                                {botSettingsErr.map((err, idx) =>(<div key={idx}>{err}</div>))}
                                            </div>
                                    }

                                    <div>
                                        <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                            <FormattedMessage id='cancel'/>
                                        </button>
                                        <Button type="primary"
                                                htmlType="submit"
                                                style={{height: 36}}
                                                className={classNames("miniBtn", {submit: botSettingsErr[0].length || botSettingsErr[1].length || firstTabError})}
                                                loading={loading._add_client || loading._edit_client}
                                        >
                                            <FormattedMessage id='save'/>
                                        </Button>
                                        <div className="error_box"><FormattedMessage id={"form_error"}/></div>
                                    </div>
                                </div>


                            </Form.Item>
                        </Form>
                    </>
            }

        </Modal>

    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
    }
}

const mapDispatchToProps = {
    addClient,
    editClient,
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ClientControlPopup));